import { FrownOutlined, MailOutlined } from '@/components2/Icons';
import { Button, Input, AutoComplete, Form } from 'antd';
import { useState } from 'react';
import SearchStore from '@/stores/SearchStore';
import { useMobxStores } from '@/stores';
import { modalType } from './Modal';
import { IAirport } from '@/types';

interface INeedMoreData {
  airport?: IAirport;
  address?: string;
  isMonthly?: boolean;
}

export default function NeedMoreData({
  airport,
  address,
  isMonthly,
}: INeedMoreData) {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleEmailChange = (value) => {
    setSelectedDomain(value);
    setOptions([]);
  };

  const domains = [
    '@gmail.com',
    '@yahoo.com',
    '@hotmail.com',
    '@live.com',
    '@outlook.com',
  ];

  const handleSearchDomain = (value) => {
    const domainIndex = value.lastIndexOf('@');
    if (domainIndex !== -1) {
      const username = value.substring(0, domainIndex);
      const domain = value.substring(domainIndex);
      const filteredOptions = domains.filter((d) => d.indexOf(domain) !== -1);
      if (filteredOptions.length > 0) {
        setOptions(filteredOptions.map((d) => username + d));
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const handleSelectDomain = (value) => {
    if (value.includes('@')) {
      setSelectedDomain(value);
      if (setEmail) {
        setEmail(value);
      }
    } else {
      setSelectedDomain(selectedDomain + value);
      if (setEmail) {
        setEmail(selectedDomain + value);
      }
    }
  };

  const doSubscribe = async () => {
    if (email) {
      await searchStore.subscribe(
        airport?.airport_id ?? 0,
        email,
        address,
        message
      );
      modalType({
        type: 'success',
        title: 'Success',
        okButtonProps: {
          className: 'bg-primary',
        },
        content: (
          <p>
            Got it! Watch your inbox for updates on new facilities. See you
            soon!
          </p>
        ),
        onOk: () => {
          setEmail('');
          setMessage('');
          form.resetFields();
        },
      });
      setEmail('');
      setMessage('');
      form.resetFields();
    }
  };

  return (
    <div
      className={`flex flex-col ${
        isMonthly ? 'mt-8' : 'mt-4'
      } justify-center items-center px-4 `}
    >
      {isMonthly && (
        <FrownOutlined className="text-primary font-bold text-7xl" />
      )}
      <span className={`font-bold text-center text-base md:text-3xl`}>
        {isMonthly
          ? "No parking now, but don't worry! Exciting monthly parking options will be launching soon!"
          : "Didn't find a parking option that meets your needs?"}
      </span>
      <span
        className={`text-base md:text-xl text-center ${isMonthly && 'mt-2'}`}
      >
        Email us now, and we'll explore additional options to better serve
        you,and keep you updated
      </span>

      <Form
        form={form}
        id="crypto-form"
        className="grid grid-cols-1 items-center"
        layout="vertical"
        onFinish={doSubscribe}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Not a valid email!',
            },
          ]}
          htmlFor="email"
          className="gap-x-7 mt-4 md:mt-6"
        >
          <AutoComplete
            value={email}
            onChange={handleEmailChange}
            options={options.map((domain) => ({ value: domain }))}
            onSelect={handleSelectDomain}
            onSearch={handleSearchDomain}
          >
            <Input
              type="email"
              className="w-60 md:w-64 border rounded-md border-gray-250 ring-0 hover:border-primary focus:shadow-md focus:border-none focus:ring-2 focus:border-primary focus:ring-yellow-500 focus:ring-opacity-20 focus:outline-none"
              inputMode="email"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              allowClear
              suffix={<MailOutlined className="text-base text-primary" />}
            />
          </AutoComplete>
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          className="gap-x-2"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Please write a message!',
            },
          ]}
          htmlFor="message"
        >
          <textarea
            id="message"
            name="message"
            rows={3}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            className={`w-60 md:w-64 border rounded-md border-gray-250 ring-0 hover:border-primary focus:shadow-md focus:border-none focus:ring-2 focus:border-primary focus:ring-yellow-500 focus:ring-opacity-20 focus:outline-none ${
              form.getFieldError('message').length > 0
                ? 'border-red-500 shadow-md ring-2 ring-red-200'
                : ''
            }`}
            spellCheck="false"
          />
        </Form.Item>
      </Form>
      <Button
        className="w-60 md:w-64 h-10 font-bold rounded-full border border-white hover:border-primary text-white hover:text-primary bg-primary hover:bg-white -mt-4 md:-mt-0 mb-4"
        onClick={doSubscribe}
      >
        Send Message
      </Button>
    </div>
  );
}
